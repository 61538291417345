@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1736239118517/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1736239118517/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1736239118517/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-external:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right-long:before { content: "\EA05" }
.icon-arrow-right:before { content: "\EA06" }
.icon-arrow-up:before { content: "\EA07" }
.icon-avalanche-training:before { content: "\EA08" }
.icon-bar:before { content: "\EA09" }
.icon-budget:before { content: "\EA0A" }
.icon-bullet-point:before { content: "\EA0B" }
.icon-calendar:before { content: "\EA0C" }
.icon-cart:before { content: "\EA0D" }
.icon-chair-lift-4:before { content: "\EA0E" }
.icon-chair-lift-6:before { content: "\EA0F" }
.icon-chair-lift-8:before { content: "\EA10" }
.icon-check:before { content: "\EA11" }
.icon-climbing:before { content: "\EA12" }
.icon-clock:before { content: "\EA13" }
.icon-close:before { content: "\EA14" }
.icon-closed:before { content: "\EA15" }
.icon-cloudy:before { content: "\EA16" }
.icon-couple-filled:before { content: "\EA17" }
.icon-couple-outline:before { content: "\EA18" }
.icon-cross-country:before { content: "\EA19" }
.icon-cutlery:before { content: "\EA1A" }
.icon-dandelion:before { content: "\EA1B" }
.icon-download:before { content: "\EA1C" }
.icon-edit:before { content: "\EA1D" }
.icon-elements:before { content: "\EA1E" }
.icon-expand:before { content: "\EA1F" }
.icon-facebook:before { content: "\EA20" }
.icon-facility:before { content: "\EA21" }
.icon-family-filled:before { content: "\EA22" }
.icon-family-outline:before { content: "\EA23" }
.icon-filter:before { content: "\EA24" }
.icon-flickr:before { content: "\EA25" }
.icon-funpark-without-border:before { content: "\EA26" }
.icon-funpark:before { content: "\EA27" }
.icon-gondola-12:before { content: "\EA28" }
.icon-gondola-8:before { content: "\EA29" }
.icon-gondola-with-border:before { content: "\EA2A" }
.icon-gondola:before { content: "\EA2B" }
.icon-group-filled:before { content: "\EA2C" }
.icon-group-outline:before { content: "\EA2D" }
.icon-hiking-special:before { content: "\EA2E" }
.icon-hiking:before { content: "\EA2F" }
.icon-home:before { content: "\EA30" }
.icon-instagram:before { content: "\EA31" }
.icon-intersport:before { content: "\EA32" }
.icon-kids-area:before { content: "\EA33" }
.icon-kids-lift:before { content: "\EA34" }
.icon-kids-park-without-border:before { content: "\EA35" }
.icon-kids-park:before { content: "\EA36" }
.icon-kids-sled-without-border:before { content: "\EA37" }
.icon-kids-sled:before { content: "\EA38" }
.icon-linkedin:before { content: "\EA39" }
.icon-loading:before { content: "\EA3A" }
.icon-mail:before { content: "\EA3B" }
.icon-mobilrouten:before { content: "\EA3C" }
.icon-mountainbike:before { content: "\EA3D" }
.icon-mouse:before { content: "\EA3E" }
.icon-open:before { content: "\EA3F" }
.icon-panorama:before { content: "\EA40" }
.icon-person-filled:before { content: "\EA41" }
.icon-person-outline:before { content: "\EA42" }
.icon-phone:before { content: "\EA43" }
.icon-pin-02:before { content: "\EA44" }
.icon-pin:before { content: "\EA45" }
.icon-pinterest:before { content: "\EA46" }
.icon-play-round:before { content: "\EA47" }
.icon-play:before { content: "\EA48" }
.icon-plug-sustainable-02:before { content: "\EA49" }
.icon-plus-thin:before { content: "\EA4A" }
.icon-plus:before { content: "\EA4B" }
.icon-preparation:before { content: "\EA4C" }
.icon-print:before { content: "\EA4D" }
.icon-remove:before { content: "\EA4E" }
.icon-search:before { content: "\EA4F" }
.icon-season:before { content: "\EA50" }
.icon-skating:before { content: "\EA51" }
.icon-skicross:before { content: "\EA52" }
.icon-skier:before { content: "\EA53" }
.icon-skiing:before { content: "\EA54" }
.icon-skilift:before { content: "\EA55" }
.icon-skitour:before { content: "\EA56" }
.icon-snowflake-detailed:before { content: "\EA57" }
.icon-snowflake-filled:before { content: "\EA58" }
.icon-snowflake-outline:before { content: "\EA59" }
.icon-snowshoe:before { content: "\EA5A" }
.icon-sos:before { content: "\EA5B" }
.icon-special:before { content: "\EA5C" }
.icon-sun-filled:before { content: "\EA5D" }
.icon-sun-outline:before { content: "\EA5E" }
.icon-sunny-clouds:before { content: "\EA5F" }
.icon-swim:before { content: "\EA60" }
.icon-swipe:before { content: "\EA61" }
.icon-ticket:before { content: "\EA62" }
.icon-tik-tok:before { content: "\EA63" }
.icon-train:before { content: "\EA64" }
.icon-trash:before { content: "\EA65" }
.icon-twitter:before { content: "\EA66" }
.icon-twitterX:before { content: "\EA67" }
.icon-upload:before { content: "\EA68" }
.icon-user:before { content: "\EA69" }
.icon-warning:before { content: "\EA6A" }
.icon-weather-cloudy:before { content: "\EA6B" }
.icon-weather-sunny:before { content: "\EA6C" }
.icon-web:before { content: "\EA6D" }
.icon-webcam:before { content: "\EA6E" }
.icon-whatsapp:before { content: "\EA6F" }
.icon-youtube:before { content: "\EA70" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-external: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-right-long: "\EA05";
    --icon-arrow-right: "\EA06";
    --icon-arrow-up: "\EA07";
    --icon-avalanche-training: "\EA08";
    --icon-bar: "\EA09";
    --icon-budget: "\EA0A";
    --icon-bullet-point: "\EA0B";
    --icon-calendar: "\EA0C";
    --icon-cart: "\EA0D";
    --icon-chair-lift-4: "\EA0E";
    --icon-chair-lift-6: "\EA0F";
    --icon-chair-lift-8: "\EA10";
    --icon-check: "\EA11";
    --icon-climbing: "\EA12";
    --icon-clock: "\EA13";
    --icon-close: "\EA14";
    --icon-closed: "\EA15";
    --icon-cloudy: "\EA16";
    --icon-couple-filled: "\EA17";
    --icon-couple-outline: "\EA18";
    --icon-cross-country: "\EA19";
    --icon-cutlery: "\EA1A";
    --icon-dandelion: "\EA1B";
    --icon-download: "\EA1C";
    --icon-edit: "\EA1D";
    --icon-elements: "\EA1E";
    --icon-expand: "\EA1F";
    --icon-facebook: "\EA20";
    --icon-facility: "\EA21";
    --icon-family-filled: "\EA22";
    --icon-family-outline: "\EA23";
    --icon-filter: "\EA24";
    --icon-flickr: "\EA25";
    --icon-funpark-without-border: "\EA26";
    --icon-funpark: "\EA27";
    --icon-gondola-12: "\EA28";
    --icon-gondola-8: "\EA29";
    --icon-gondola-with-border: "\EA2A";
    --icon-gondola: "\EA2B";
    --icon-group-filled: "\EA2C";
    --icon-group-outline: "\EA2D";
    --icon-hiking-special: "\EA2E";
    --icon-hiking: "\EA2F";
    --icon-home: "\EA30";
    --icon-instagram: "\EA31";
    --icon-intersport: "\EA32";
    --icon-kids-area: "\EA33";
    --icon-kids-lift: "\EA34";
    --icon-kids-park-without-border: "\EA35";
    --icon-kids-park: "\EA36";
    --icon-kids-sled-without-border: "\EA37";
    --icon-kids-sled: "\EA38";
    --icon-linkedin: "\EA39";
    --icon-loading: "\EA3A";
    --icon-mail: "\EA3B";
    --icon-mobilrouten: "\EA3C";
    --icon-mountainbike: "\EA3D";
    --icon-mouse: "\EA3E";
    --icon-open: "\EA3F";
    --icon-panorama: "\EA40";
    --icon-person-filled: "\EA41";
    --icon-person-outline: "\EA42";
    --icon-phone: "\EA43";
    --icon-pin-02: "\EA44";
    --icon-pin: "\EA45";
    --icon-pinterest: "\EA46";
    --icon-play-round: "\EA47";
    --icon-play: "\EA48";
    --icon-plug-sustainable-02: "\EA49";
    --icon-plus-thin: "\EA4A";
    --icon-plus: "\EA4B";
    --icon-preparation: "\EA4C";
    --icon-print: "\EA4D";
    --icon-remove: "\EA4E";
    --icon-search: "\EA4F";
    --icon-season: "\EA50";
    --icon-skating: "\EA51";
    --icon-skicross: "\EA52";
    --icon-skier: "\EA53";
    --icon-skiing: "\EA54";
    --icon-skilift: "\EA55";
    --icon-skitour: "\EA56";
    --icon-snowflake-detailed: "\EA57";
    --icon-snowflake-filled: "\EA58";
    --icon-snowflake-outline: "\EA59";
    --icon-snowshoe: "\EA5A";
    --icon-sos: "\EA5B";
    --icon-special: "\EA5C";
    --icon-sun-filled: "\EA5D";
    --icon-sun-outline: "\EA5E";
    --icon-sunny-clouds: "\EA5F";
    --icon-swim: "\EA60";
    --icon-swipe: "\EA61";
    --icon-ticket: "\EA62";
    --icon-tik-tok: "\EA63";
    --icon-train: "\EA64";
    --icon-trash: "\EA65";
    --icon-twitter: "\EA66";
    --icon-twitterX: "\EA67";
    --icon-upload: "\EA68";
    --icon-user: "\EA69";
    --icon-warning: "\EA6A";
    --icon-weather-cloudy: "\EA6B";
    --icon-weather-sunny: "\EA6C";
    --icon-web: "\EA6D";
    --icon-webcam: "\EA6E";
    --icon-whatsapp: "\EA6F";
    --icon-youtube: "\EA70";
    
}